/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
.mat-form-field-infix{ background-color: #FFF!important; border-top:none!important; border-radius: 5px;}
.mat-form-field-flex{
  background-color: #FFF!important; padding: 10px;
  border-radius: 5px;
}
.sidebar-dark .nav-item .nav-link{
  color: #A7A9C0!important;
}
.modal{
  background-color: rgb(0,0,0,0.5)!important;
}

.sidebar-dark .nav-item .nav-link i{
  color: #A7A9C0!important;
}
.sidebar .active {
  background-color: #4AD9A4;
  color: #FFF!important;
 border-left: none!important;
}
.sidebar-dark .nav-item.active .nav-link {
  color: #fff!important;
}
.sidebar-dark .nav-item.active .nav-link i{
  color: #fff!important;
}
.sidebar-dark .nav-item.active .nav-link:hover {
  background-color: #4AD9A4;
  color: #fff!important;
  border-left: none!important;
}
.sidebar-dark .nav-item.active .nav-link:hover i{
  color: #fff!important;
}
@media (min-width: 768px){
  .sidebar .nav-item .nav-link span {
    font-size: 16px;
    display: inline;
  }
}


@media (max-width: 768px){
  .fonte_menu{
    font-size: 10px!important;
    text-align: left;
  }
.sidebar-brand-icon{
  display: block!important;
  margin-left: 0px!important;
}
  .fonte_menu_a{
    font-size: 8px!important;
  }
  .escodericone{
    display: none!important;
  }
}



@media (max-width: 575px) {

  .sidebar-brand-icon{
    display: block!important;
    margin-left: -130px!important;
  }

}
.sidebar .nav-item:hover {
  background-color: #f8f9fc;
  border-left: 4px solid #4AD9A4 !important;
}

.sidebar .nav-item:hover a {
  color: #4AD9A4!important;
}
h2  {
 margin: 0!important;
}


/* Largura da barra de rolagem */
::-webkit-scrollbar {
  width: 10px;
}

/* Fundo da barra de rolagem */
::-webkit-scrollbar-track-piece {
  background-color: #EEE;
  border-left: 1px solid #CCC;
}

/* Cor do indicador de rolagem */
::-webkit-scrollbar-thumb:vertical,
::-webkit-scrollbar-thumb:horizontal {
  background-color: #91D9BF;
}

/* Cor do indicador de rolagem - ao passar o mouse */
::-webkit-scrollbar-thumb:vertical:hover,
::-webkit-scrollbar-thumb:horizontal:hover {
  background-color: #4AD9A4;
}
.nomeativo{
  display: block!important;
}
.btn-light2{
  background-color: #FFF;
font-size: 12px;
}
.btn-light2:hover{
background-color: #1BA9EA;
  color: #FFF;
}
.btn-selecinado{
background-color: #1BA9EA;
  color: #FFF;
}
.buscar::-webkit-input-placeholder{
 font-size: 12px;
}
.buscar::-moz-placeholder {
  font-size: 12px;
}
.menu_editar  li:hover a {
  color: #4AD9A4!important;
  cursor: pointer;
}
.menu_editar  .ativo_editar a {
  color: #4AD9A4!important;
  cursor: pointer;
}
.menu_editar  .ativo_editar a svg path{
  color: #4AD9A4!important;
  cursor: pointer;
  fill: #4AD9A4;
}

.menu_editar  li:hover a i{
  color: #4AD9A4!important;
display: inline!important;
fill: #4AD9A4;
}
.menu_editar  li:hover a svg path{
  color: #4AD9A4!important;
display: inline!important;
fill: #4AD9A4;
}
.ativo_editar  li a svg path{
  color: #4AD9A4!important;
display: inline!important;
fill: #4AD9A4;
}
.menu_editar  .ativo_editar a i{
  color: #4AD9A4!important;
  display: inline!important;

}
.form-control:focus {
  color: #6e707e;
  background-color: #fff;
  border-top:3px #4AD9A4 solid !important;
  border-color: #4AD9A4!important;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(74 217 164 / 100%);
}
body.sidebar-toggled footer.sticky-footer{
  margin: 10px!important;
}
.tr_espace{
  border-bottom: 6px solid #F0F2F7; border-top:6px solid #F0F2F7;
}
.tr_espace td{
  line-height: 40px;
}
.sombra2 {
  box-shadow:none!important;

}
.sombra {
  box-shadow:none!important;
}
.ativo_editar, ::after, ::before {
  box-sizing: initial!important;
}
